// Importations nécessaires
import { fetchQuery, useClientQuery } from 'react-relay';
import HomePage from '@/components/pages/home.js';
import { initEnvironment } from '../relay';

export async function getServerSideProps(context) {
  const i18n = await import('../i18n.js');
  const tradFromServer = await i18n.getByPages({ routes: ['*', '/'], locale: context.locale });

  const Component = await import('@/components/pages/home.js');
  const { query, queryVariables } = Component;

  const category = 'meeting';
  const variables = { ...queryVariables({ route: category }), lang: context.locale };

  const environment = initEnvironment();

  try {
    const data = await fetchQuery(environment, query, variables, { force: true }).toPromise();
    // console.log('Data fetched successfully:', data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }

  const queryRecords = environment.getStore().getSource().toJSON();

  return {
    props: {
      query,
      variables,
      animateHeader: true,
      queryRecords,
      tradFromServer,
    },
  };
}

export default function Home({ variables, query }) {
  const data = useClientQuery(query, variables);
  return (<HomePage {...data} {...{ variables }} />);
}
